/* eslint no-unused-vars: 0 */

import { navigate } from "gatsby";
import Button from "antd/lib/button";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Select from "antd/lib/select";
import PropTypes from "prop-types";
import React from "react";
// import Select from 'react-select';

// import Amplify, { API } from "aws-amplify";
// import awsconfig from "../../aws-exports";

// Amplify.configure(awsconfig);

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
import "antd/lib/form/style/index.css";
import "antd/lib/input/style/index.css";
import "antd/lib/select/style/index.css";
import "antd/lib/button/style/index.css";
import { ThemeContext } from "../../layouts";

const ComponentAPI = props => {
  const { getFieldDecorator } = props.form;

  function handleChange(selectedOption) {
    // this.setState({ selectedOption });
    console.log(`Option selected:`, selectedOption);
    if (selectedOption === "msisdn") {
      document.getElementById("item-msisdn").style.display = "block";
      document.getElementById("item-imsi").style.display = "none";
    } else if (selectedOption === "imsi") {
      document.getElementById("item-imsi").style.display = "block";
      document.getElementById("item-msisdn").style.display = "none";
    }
  }

  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  function handleSubmit(e) {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        // console.log("Received values of form: ", values);
        handleLookup(values);
      }
    });
  }

  function handleLookup(values) {
    const { service, msisdn, imsi } = values;

    try {
      if (service === undefined) throw new Error("No service selected");

      if (service === "msisdn" && msisdn === undefined) throw new Error("No MSISDN");

      if (service === "imsi" && imsi === undefined) throw new Error("No IMSI");

      const value = service === "msisdn" ? msisdn : imsi;

      document.getElementById("result").innerHTML =
        '<pre class="language-javascript">Looking up ' + value + "...</pre>";

      fetch(`https://api.msisdn.net/${service}/${value}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" }
      })

      // API.get("msisdn", `/${service}/${value}`) //, { body: values })

        // .then((response) => {
        //   return response.json();
        // })
        .then(json => {
          return json.json()
        })
        .then(json => {
          // console.log("Form submission success", json);
          delete json.note;
          json = JSON.stringify(json, null, "  ");
          document.getElementById("result").innerHTML =
            '<pre class="language-javascript">' + json + "</pre>";
          // navigate("/success");
        });
    } catch (err) {
      handleNetworkError(err);
    }
  }

  function handleNetworkError(e) {
    console.error("submit Error", e);
  }

  return (
    <React.Fragment>
      <ThemeContext.Consumer>
        {theme => (
          <div id="lookup" className="form">
            <Form name="api" onSubmit={handleSubmit}>
              <FormItem label="Service">
                {getFieldDecorator("service", {
                  initialValue: "msisdn"
                })(
                  <Select name="service" onChange={handleChange}>
                    <Option value="msisdn">MSISDN</Option>
                    <Option value="imsi">IMSI</Option>
                  </Select>
                )}
              </FormItem>

              <div id="item-msisdn">
                <FormItem label="MSISDN">
                  {getFieldDecorator("msisdn", {
                    rules: [
                      {
                        whitespace: false,
                        type: "string"
                      }
                    ]
                  })(<Input id="msisdn" name="msisdn" placeholder="MSISDN" />)}
                </FormItem>
              </div>

              <div id="item-imsi">
                <FormItem label="IMSI">
                  {getFieldDecorator("imsi", {
                    rules: [
                      {
                        whitespace: false,
                        type: "string"
                      }
                    ]
                  })(<Input id="imsi" name="imsi" placeholder="IMSI" />)}
                </FormItem>
              </div>

              <FormItem>
                <Button type="primary" htmlType="submit">
                  Lookup
                </Button>
              </FormItem>
            </Form>

            <div id="result"></div>

            {/* --- STYLES --- */}
            <style jsx>{`
              .form {
                background: transparent;
              }
              .form :global(.ant-row.ant-form-item) {
                margin: 0 0 1em;
              }
              .form :global(.ant-row.ant-form-item:last-child) {
                margin-top: 1em;
              }
              .form :global(.ant-form-item-control) {
                line-height: 1em;
              }
              .form :global(.ant-form-item-label) {
                line-height: 1em;
                margin-bottom: 0.5em;
              }
              .form :global(.ant-form-item) {
                margin: 0;
              }
              .form :global(.ant-input, .ant-select-selection--single) {
                appearance: none;
                height: auto;
                font-size: 1.2em;
                padding: 0.5em 0.6em;
              }
              .form :global(.ant-btn-primary) {
                height: auto;
                font-size: 1.2em;
                padding: 0.5em 3em;
                background: ${theme.color.brand.primary};
                border: 1px solid ${theme.color.brand.primary};
              }
              .form :global(.ant-form-explain) {
                margin-top: 0.2em;
              }

              @from-width desktop {
                .form :global(input) {
                  max-width: 100%;
                }
              }

              #item-imsi {
                display: none;
              }
            `}</style>
          </div>
        )}
      </ThemeContext.Consumer>
    </React.Fragment>
  );
};

ComponentAPI.propTypes = {
  form: PropTypes.object
};

const APIForm = Form.create({})(ComponentAPI);

export default APIForm;
